<template>
  <section class="vSectionIntegration section-profile">
    <h2 class="section-profile__title">
      Выберите систему
    </h2>
    <ul class="vSectionIntegration__list">
      <li class="vSectionIntegration__item">
        <div class="vSectionIntegration__icon">
          <img
            src="~@/assets/images/logo-r_keeper.png"
            alt="rkeeper"
          >
        </div>
        <button
          v-if="SERVICES?.name === 'r-keeper'"
          class="vSectionIntegration__btn"
          :class="{ active: SERVICES?.name === 'r-keeper' }"
        >
          Запрос отправлен
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12.5"
              cy="12"
              r="9.25"
              fill="white"
              stroke="#25C26E"
              stroke-width="1.5"
            />
            <path
              d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
              stroke="#25C26E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button
          v-else
          class="vSectionIntegration__btn"
          :disabled="SERVICES !== null && SERVICES?.name !== 'r-keeper'"
          @click="openModal('r-keeper')"
        >
          Интегрировать
        </button>
      </li>
      <li class="vSectionIntegration__item">
        <div class="vSectionIntegration__icon">
          <img
            src="~@/assets/images/logo-iiko.png"
            alt="iiko"
          >
        </div>
        <button
          v-if="SERVICES?.name === 'iiko'"
          class="vSectionIntegration__btn"
          :class="{ active: SERVICES?.name === 'iiko' }"
        >
          Запрос отправлен
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12.5"
              cy="12"
              r="9.25"
              fill="white"
              stroke="#25C26E"
              stroke-width="1.5"
            />
            <path
              d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
              stroke="#25C26E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button
          v-else
          class="vSectionIntegration__btn"
          :disabled="SERVICES !== null && SERVICES?.name !== 'iiko'"
          @click="openModal('iiko')"
        >
          Интегрировать
        </button>
      </li>
      <li class="vSectionIntegration__item">
        <div class="vSectionIntegration__icon">
          <p>У меня другая система</p>
        </div>
        <button
          v-if="SERVICES?.name === 'other'"
          class="vSectionIntegration__btn"
          :class="{ active: SERVICES?.name === 'other' }"
        >
          Запрос отправлен
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12.5"
              cy="12"
              r="9.25"
              fill="white"
              stroke="#25C26E"
              stroke-width="1.5"
            />
            <path
              d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
              stroke="#25C26E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button
          v-else
          class="vSectionIntegration__btn"
          :disabled="SERVICES !== null && SERVICES?.name !== 'other'"
          @click="openModal('other')"
        >
          Интегрировать
        </button>
      </li>
    </ul>

    <!-- r-keeper -->
    <vModal
      v-if="modal === 'r-keeper'"
      @closeModal="closeModal"
    >
      <template #title>
        Предупреждение
      </template>
      <template #body>
        <p>Вы уверены, что хотите интегироваться с rkeeper</p>
      </template>
      <template #footer>
        <vButton @handleClick="integration">
          Интегрировать
        </vButton>
        <vButton
          theme="gray"
          @handleClick="closeModal"
        >
          Отменить
        </vButton>
      </template>
    </vModal>

    <!-- iiko -->
    <vModal
      v-if="modal === 'iiko'"
      @closeModal="closeModal"
    >
      <template #title>
        Предупреждение
      </template>
      <template #body>
        <p>Вы уверены, что хотите интегироваться с iiko</p>
      </template>
      <template #footer>
        <vButton @handleClick="integration">
          Интегрировать
        </vButton>
        <vButton
          theme="gray"
          @handleClick="closeModal"
        >
          Отменить
        </vButton>
      </template>
    </vModal>

    <!-- Другие системы -->
    <vModal
      v-if="modal === 'other'"
      :style-bottom="true"
      @closeModal="closeModal"
    >
      <template #title>
        Отправка запроса
      </template>
      <template #body>
        <textarea
          class="vSectionIntegration__textarea"
          placeholder="С какой системой вы хотите интегрироваться?"
        />
      </template>
      <template #footer>
        <vButton @handleClick="integration">
          Интегрировать
        </vButton>
        <vButton
          theme="gray"
          @handleClick="closeModal"
        >
          Отменить
        </vButton>
      </template>
    </vModal>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vModal from '@/components/modals/v-modal'
import vButton from '@/components/v-button'
export default {
  name: 'VSectionIntegration',
  components: {
    vModal,
    vButton,
  },
  data() {
    return {
      modal: null,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('integration', ['SERVICES']),
  },
  async mounted() {
    await this.GET_INTEGRATION_SERVIVES({ token: this.GET_AUTHENTIFICATED })
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('integration', ['INTEGRATION', 'GET_INTEGRATION_SERVIVES']),
    async integration() {
      this.TOGGLE_PRELOADER(true)
      await this.INTEGRATION({
        token: this.GET_AUTHENTIFICATED,
        service: this.modal,
      })
      this.closeModal()
      this.TOGGLE_PRELOADER(false)
    },
    openModal(val) {
      this.modal = val
    },
    closeModal() {
      this.modal = null
    },
  },
}
</script>

<style lang="scss">
.vSectionIntegration {
  &__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    @media (min-width: 769px) {
      flex-direction: row;
      // justify-content: space-between;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    margin-bottom: 16px;
    min-height: 254px;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    background: #fff;
    @media (min-width: 769px) {
      flex-grow: 1;
      max-width: 337px;
      margin-right: 32px;
      padding-top: 0;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    flex: 1;
    img {
      width: 164px;
    }
    p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #010d35;
    }
  }
  &__btn {
    width: 100%;
    padding: 16px;
    font-family: 'Commissioner';
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #ffffff;
    border-radius: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #343d5d;
    background: #edeef1;
    cursor: pointer;
    &:disabled {
      color: #80869a;
      cursor: default;
      &:hover {
        color: #80869a;
        background: #edeef1;
      }
    }
    &:hover {
      color: #ffffff;
      background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    }
    &.active {
      background: #edeef1;
      cursor: default;
      color: #343d5d;
    }
    svg {
      margin-left: 10px;
    }
  }
  &__textarea {
    padding: 16px 0;
    width: 100%;
    height: 216px;
    font-family: 'Commissioner';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    outline: none;
    resize: none;
    margin-bottom: 24px;
    border-top: 1px solid #edeef1;
  }
}
</style>
